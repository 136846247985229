@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-800 bg-gray-300;

  /* Overriding React Toastify CSS variables */

  --toastify-color-success: #6530F3;
  --toastify-color-error: #F93751;

  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-color-progress-success: var(--toastify-color-success);

  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-color-progress-error: var(--toastify-color-error);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  @apply text-primary
}
